import { Suspense, useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Route, Routes as Router, useLocation, useNavigate } from 'react-router-dom';
import LoadingPage from 'components/UI/LoadingPage/LoadingPage';
import { comparePaths } from './helpers';
import paths from './paths';

export default function Routes() {
	const location = useLocation();
	const navigate = useNavigate();
	const queryClient = new QueryClient({
		defaultOptions: {
			queries: {
				refetchOnWindowFocus: false,
			},
		},
	});

	useEffect(() => {
		const currentPage = paths.find((path) => comparePaths(location.pathname, path.pathname));
		if (!currentPage) navigate('/');
	}, [location]);

	return (
		<Suspense fallback={<LoadingPage />}>
			<QueryClientProvider client={queryClient}>
				<Router>
					{paths.map(({ page: Page, pathname, ...rest }) => (
						<Route key={pathname}>
							<Route index={pathname === '/'} path={pathname} element={<Page {...rest} />} />
						</Route>
					))}
				</Router>
			</QueryClientProvider>
		</Suspense>
	);
}
