const theme = {
	colors: {
		white: '#FFFFFF',
		mineShaft: '#333333',
		tundora: '#444444',
		doveGray: '#666666',
		dustyGray: '#999999',
		gallery: '#ECECEC',
		emperor: '#4f4f4f',
		gray: '#888888',
		wildSand: '#F6F6F6',
		alto: '#d1d1d1',
	},
	fonts: {
		sourceSansPro: 'Source Sans Pro, sans-serif',
	},
	media: {
		xs: '360px',
		sm: '576px',
		md: '768px',
		lg: '1120px',
		xl: '1248px',
		xxl: '1440px',
	},
};

export default theme;
