import { createGlobalStyle } from 'styled-components';
import { getThemeColor, getThemeFont, getThemeMedia } from './helpers';

const GlobalStyles = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
  }

  * {
    margin: 0;
    padding: 0;
    font-family: ${getThemeFont('sourceSansPro')};
		text-decoration: none;
  }

	*:not(html, body, #root) {
		@media screen {
			@media (min-width: ${getThemeMedia('lg')}) {
				&::-webkit-scrollbar-track {
					border-radius: 8px;
					background-color: ${getThemeColor('gallery')};
				}

				&::-webkit-scrollbar {
					background-color: ${getThemeColor('gallery')};
					width: 10px;
				}

				&::-webkit-scrollbar-thumb {
					border-radius: 8px;
					background-color: ${getThemeColor('dustyGray')};
				}
			}
		}
	}

	#root {
		width: 100%;
	}

	html {
		font-size: 16px;
	}

  html, body {
    height: 100%;
  }

  body {
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
		background-color: ${getThemeColor('white')};
  }

  img, picture, video, canvas, svg {
    display: block;
    max-width: 100%;
  }

  input, button, textarea, select {
    font: inherit;
  }

  button {
    appearance: none;
    border: none;
    background-color: transparent;
  }

  p, h1, h2, h3, h4, h5, h6 {
    overflow-wrap: break-word;
  }

  #root, #__next {
		display: flex;
    flex-direction: column;
		min-height: 100vh;
    isolation: isolate;
  }

  a {
    color: ${getThemeColor('doveGray')};
  }

	ul {
		padding: 0;
	}
`;

export default GlobalStyles;
