import Loading from 'components/Loading';
import { LoadingPageProps } from './interfaces';
import { LoadingPageContainer } from './styles';

export default function LoadingPage({ loaderColor }: LoadingPageProps) {
	return (
		<LoadingPageContainer>
			<Loading size="24" color={loaderColor} />
		</LoadingPageContainer>
	);
}
