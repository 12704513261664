import { GetterResponse, ThemeColorsTypes, ThemeFontsTypes, ThemeMediaBPTypes } from './interfaces';

export const getThemeFont = (font?: ThemeFontsTypes): GetterResponse => {
	return (props) => props.theme.fonts[font || 'sourceSansPro'];
};

export const getThemeColor = (color?: ThemeColorsTypes): GetterResponse => {
	return (props) => props.theme.colors[color || 'mineShaft'];
};

export const getThemeMedia = (bp?: ThemeMediaBPTypes): GetterResponse => {
	return (props) => props.theme.media[bp || 'xs'];
};
